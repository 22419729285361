import React from 'react';
import './Break.css';

function Break() {
  return (
<svg className="break" width="250" height="35" viewBox="0 0 404 34" fill="#000" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.2" d="M16 13H6L0 21H16H32H42L48 13H32H16Z"></path>
    <path opacity="0.5" d="M72 13H62L56 21H72H88H98L104 13H88H72Z"></path>
    <path d="M128 13H118L112 21H128H144H154L160 13H144H128Z"></path>
    <path d="M260 13H250L244 21H260H276H286L292 13H276H260Z"></path>
    <path opacity="0.5" d="M316 13H306L300 21H316H332H342L348 13H332H316Z"></path>
    <path opacity="0.2" d="M372 13H362L356 21H372H388H398L404 13H388H372Z"></path>
    <path d="M185 0L202 34H168L185 0ZM202 34L220 0H236L218 34H202Z"></path>
    <path d="M202 0L202 8L212 17L202 26L202 34L192 26L202 17L192 8L202 0Z" fill="#FFFFFF"></path>
</svg>
  );
}

export default Break;
