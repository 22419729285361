import React from "react";
import "./Footer.css";

// Social link component
const SocialLink = ({ href, iconClass }) => (
  <li>
    <a href={href} target="_blank" rel="noopener noreferrer">
      <i className={iconClass}></i>
    </a>
  </li>
);

// Footer link component
const FooterLink = ({ href, text }) => (
  <li>
    <a href={href} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  </li>
);

// Array of social links
const socialLinks = [
  { href: "https://twitter.com/HoodRatNFTs", iconClass: "bi bi-twitter" },
  { href: "https://discord.gg/8NprDwRf2n", iconClass: "bi bi-discord" },
  { href: "https://www.instagram.com/hoodrats.eth/", iconClass: "bi bi-instagram" },
  { href: "https://t.me/labarberiaweb3", iconClass: "bi bi-telegram" },
  { href: "https://www.youtube.com/@labarberiaweb3", iconClass: "bi bi-youtube" },
  { href: "https://medium.com/@tharealjharmony", iconClass: "bi bi-medium" },
];

// Array of footer links
const footerLinks = [
  { href: "https://medium.com/@tharealjharmony", text: "Blog" },
  { href: "https://docs.google.com/document/d/e/2PACX-1vSJsaqV1Jc4bB08Hn6QBLvP6ExuoMdUKsWzE7kIkjlBeXgTmNbklD05k20xHIfkjgp1PWH3s8PLZT81/pub", text: "Privacy" },
  { href: "https://mint_hoodrats.ar-io.dev/build/images/HR_TOS.pdf", text: "Terms of use" },
  { href: "https://mint_hoodrats.ar-io.dev/", text: "Mint" },
  { href: "https://jharmony.github.io/developerFolio/", text: "Founder" },
];

export default function Footer() {
  return (
    <section className="Footer">
      <div className="container">
        <div className="row">
          <div className="join-us">
            <h1>
              JOIN VIA
              <br />
              <span>DISCORD</span>
            </h1>
            <p className="subtitle">Become a part of our larger La Barbería Web3 family.</p>
            <div className="cta">
              <a href="https://discord.gg/8NprDwRf2n" target="_blank" rel="noopener noreferrer">
                Join Discord
              </a>
            </div>
          </div>

          <ul className="social-links">
            {socialLinks.map((link, index) => (
              <SocialLink key={index} href={link.href} iconClass={link.iconClass} />
            ))}
          </ul>

          <ul className="footer-links">
            {footerLinks.map((link, index) => (
              <FooterLink key={index} href={link.href} text={link.text} />
            ))}
          </ul>

          <p className="copy">© 2023 HEMPHEADS. All rights reserved.</p>
          <p className="credit">Hood Rats 🐀 by <a href="https://github.com/jharmony" target="_blank" rel="noopener noreferrer">@jharmony</a></p>
        </div>
      </div>
    </section>
  );
}
