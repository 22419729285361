import 'bootstrap-icons/font/bootstrap-icons.css';
import React from 'react';
import './Hero.css';

export default function Hero() {

  return (
    <section className="hero">
      <div className="container-fluid">
        <div className="svg-wrapper-top svg-left-top">
        <svg width="32" height="32" viewBox="0 0 72 72" fill="#3B5998" xmlns="http://www.w3.org/2000/svg">
        <circle cx="36" cy="36" r="30" fill="#9351f7" />
        <circle cx="25" cy="20" r="15" fill="#2E3B4E" />
        <circle cx="50" cy="50" r="10" fill="#3B5998" />
       </svg>
        </div>
        <div className="svg-wrapper-top svg-right-top">
        <svg width="32" height="32" viewBox="0 0 72 72" fill="#34A853" xmlns="http://www.w3.org/2000/svg">
    <circle cx="36" cy="36" r="28" fill="#F4B400" />
    <circle cx="50" cy="20" r="12" fill="#EA4335" /> 
    <circle cx="20" cy="50" r="8" fill="#4285F4" />
    <circle cx="60" cy="60" r="5" fill="#0F9D58" /> 
</svg>
        </div>
        <div className="svg-wrapper-bottom svg-left-bottom">
        <svg width="32" height="32" viewBox="0 0 72 72" fill="#FFD700" xmlns="http://www.w3.org/2000/svg">
    <circle cx="36" cy="36" r="28" fill="#FFD700" /> 
    <circle cx="36" cy="36" r="22" fill="#F0E68C" /> 
    <circle cx="36" cy="36" r="30" stroke="#CD853F" stroke-width="4" fill="transparent" />
</svg>
        </div>
        <div className="svg-wrapper-bottom svg-right-bottom">
        <svg width="48" height="48" viewBox="0 0 128 128" fill="#0099CC" xmlns="http://www.w3.org/2000/svg">
    <circle cx="64" cy="64" r="40" fill="#FF6347" /> 
    <circle cx="32" cy="40" r="10" fill="#FFD700" /> 
    <circle cx="96" cy="80" r="15" fill="#8B4513" /> 
    <circle cx="20" cy="100" r="20" fill="#00FF7F" /> 
    <circle cx="110" cy="25" r="9" fill="#FFA500" /> 
</svg>
        </div>
        <div className="content">
          <h1>Hood Rats<br /><span>Digital Collectables</span></h1>

          <div className="container panel">
            <div className="row">
              <div className="col">
                <img className="img secondary-image" src={require('../images/01.jpg')} alt="01" />
                <img className="img secondary-image" src={require('../images/111.jpg')} alt="111" />
              </div>
              <div className="col">
                <img className="img tertiary-image" src={require('../images/02.jpg')} alt="02" />
                <img className="img tertiary-image" src={require('../images/03.jpg')} alt="03" />
                <img className="img tertiary-image" src={require('../images/33.jpg')} alt="33" />
              </div>
              <div className="col">
                <img className="img main-image" src={require('../images/04.jpg')} alt="04" />
              </div>
              <div className="col">
                <img className="img tertiary-image" src={require('../images/05.jpg')} alt="05" />
                <img className="img tertiary-image" src={require('../images/06.jpg')} alt="06" />
                <img className="img tertiary-image" src={require('../images/66.jpg')} alt="66" />
              </div>
              <div className="col">
                <img className="img secondary-image" src={require('../images/07.jpg')} alt="07" />
                <img className="img secondary-image" src={require('../images/77.jpg')} alt="77" />
              </div>
            </div>
          </div>
    
        </div>
      </div>
    </section>
  );
};
