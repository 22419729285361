import React, { useState } from "react";
import "./QnA.css";

export default function QnA() {
  // Separate states for each card's active question
  const [activeQuestionLeft, setActiveQuestionLeft] = useState(null);
  // const [activeQuestionRight, setActiveQuestionRight] = useState(null);

  const toggleAnswerLeft = (questionId) => {
    setActiveQuestionLeft((prevQuestionId) =>
      prevQuestionId === questionId ? null : questionId
    );
  };

  // const toggleAnswerRight = (questionId) => {
  //   setActiveQuestionRight((prevQuestionId) =>
  //     prevQuestionId === questionId ? null : questionId
  //   );
  // };

  const questions = [
    {
      id: 1,
      question: "What does the term digital collectible mean?",
      answer:
        "A digital collectible, also known as a digital collectable or a non-fungible token (NFT), is a unique and indivisible digital asset that represents ownership or proof of authenticity of a specific item or piece of content using blockchain technology. Unlike cryptocurrencies such as Bitcoin or Ethereum, which are fungible and can be exchanged on a one-to-one basis, NFTs are distinct and cannot be exchanged on a like-for-like basis because each NFT has a unique identifier.",
    },
    {
      id: 2,
      question: "How can we purchase and invest in NFTs?",
      answer:
        "To purchase and invest in NFTs, you'll first need a digital wallet like MetaMask or Trust Wallet. After funding your wallet with cryptocurrency like Ether (ETH), explore NFT marketplaces such as OpenSea or Rarible to find digital assets that interest you. Conduct research on the artists, uniqueness, and potential resale value of the NFTs you're interested in. When you find one you like, you can either place a bid or buy it outright. Once purchased, the NFT will be transferred to your wallet. Remember to secure your digital assets and stay informed about market trends to make informed investment decisions.",
    },
    {
      id: 3,
      question: "How does blockchain ensure the security and immutability of digital transactions?",
      answer:
        "Blockchain ensures the security and immutability of digital transactions through its decentralized and cryptographic nature. Transactions are encrypted and recorded in blocks, with each block containing a unique code (hash) and a reference to the previous block, forming an unbreakable chain. The decentralized network of computers validates transactions through consensus algorithms, ensuring unanimity before adding them to the blockchain. Once a block is added, altering its data would require changing all subsequent blocks across the entire network, making it practically impossible to tamper with. Additionally, the information is stored on multiple nodes, enhancing security further. These features collectively create a highly secure and unchangeable ledger, making blockchain technology reliable for a wide range of applications, including cryptocurrencies, smart contracts, and supply chain management.",
    },
    {
      id: 4,
      question: "What impact does blockchain technology have on traditional financial systems and payment methods?",
      answer:
        "Blockchain technology has profoundly impacted traditional financial systems and payment methods by introducing efficiency, security, and accessibility. Through decentralization, transactions occur directly between parties, eliminating the need for intermediaries and reducing costs. Blockchain enables rapid, low-cost cross-border transactions, making it particularly advantageous for global payments and remittances. Its secure, cryptographic protocols enhance data integrity, protecting against fraud and ensuring user privacy. Moreover, the technology promotes financial inclusion by providing banking services to unbanked populations worldwide. Smart contracts automate agreements, reducing reliance on legal frameworks. Additionally, the tokenization of assets and the rise of decentralized finance (DeFi) platforms have opened new avenues for investment and innovation, challenging traditional financial paradigms and fostering a more inclusive and efficient global financial ecosystem.",
    },
    {
      id: 5,
      question: "How does ARdrive leverage Arweave's permanent and decentralized data storage capabilities to enhance the functionality and security of augmented reality (AR) applications?",
      answer:
        "ARdrive leverages Arweave's permanent and decentralized data storage capabilities to significantly enhance the functionality and security of augmented reality (AR) applications. By utilizing Arweave's blockchain-based storage, ARdrive ensures that AR content, such as 3D models, textures, and interactive elements, is stored securely and permanently. Traditional centralized storage solutions are vulnerable to data loss, censorship, and tampering, but Arweave's decentralized architecture guarantees the permanence of AR assets. This not only provides a reliable and tamper-proof storage solution but also enables AR applications to fetch content seamlessly and quickly, enhancing the overall user experience. Furthermore, the decentralized nature of Arweave ensures data integrity and reduces the risk of single points of failure, making AR applications powered by ARdrive more resilient and trustworthy for users and developers alike.",
    },
  ];

  return (
    <section className="QnA">
      <div className="container-fluid">
        <div className="row">
          <h2>ASKED <span>QUESTIONS</span></h2>
        </div>
        <div className="row">
          <div className="col-md-6 card">
            {questions.map((q, index) => (
              <div className="question" key={q.id}>
                <h5 onClick={() => toggleAnswerLeft(q.id)} data-active={activeQuestionLeft === q.id}>
                  {q.question}
                  {activeQuestionLeft === q.id ? (
                    <i className="bi bi-dash-lg"></i>
                  ) : (
                    <i className="bi bi-plus-lg"></i>
                  )}
                </h5>
                {activeQuestionLeft === q.id && (
                  <p>{q.answer}</p>
                )}
                {index !== questions.length - 1 && <hr />}
              </div>
            ))}
          </div>

          {/* <div className="col-md-6 card">
            {questions.map((q, index) => (
              <div className="question" key={q.id}>
                <h5 onClick={() => toggleAnswerRight(q.id)} data-active={activeQuestionRight === q.id}>
                  {q.question}
                  {activeQuestionRight === q.id ? (
                    <i className="bi bi-dash-lg"></i>
                  ) : (
                    <i className="bi bi-plus-lg"></i>
                  )}
                </h5>
                {activeQuestionRight === q.id && (
                  <p>{q.answer}</p>
                )}
                {index !== questions.length - 1 && <hr />}
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </section>
  );
}
