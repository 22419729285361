import {
  ConnectWallet,
  useAddress,
  useContract,
  useMetadata,
  useContractMetadata,
  darkTheme,
  useContractRead,
} from "@thirdweb-dev/react";
import styles from "./Mint.css";
import MyAbi from "../abi/hrabi.json";
import { ethers } from "ethers";
// import { parseUnits } from 'ethers/lib/utils'; // Corrected import

const Mint = () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const contractAddress = "0xA05803e679f517cFD9f20031816aB57A7b2Fc2d3";
  const contractAbi = MyAbi;
  const nftContract = new ethers.Contract(contractAddress, contractAbi, signer);

  const address = useAddress();

  async function mintNFT() {
    try {
      const tokenId = 1;
      const balance = await provider.getBalance(address);

      // Estimate gas cost
      const transactionCost = await nftContract.estimateGas.mint(tokenId, {
        value: ethers.utils.parseUnits("0.025", 18),
      });
      const totalCost = transactionCost.mul(await provider.getGasPrice());

      // Check if the balance is sufficient
      if (balance.lt(totalCost)) {
        alert(
          `Insufficient funds to proceed. Please add more ETH to your wallet.`
        );
        return;
      }

      // Mint NFT
      const transaction = await nftContract.mint(tokenId, {
        value: ethers.utils.parseUnits("0.025", 18),
      });
      await transaction.wait();

      alert("NFT Minted successfully!");
    } catch (error) {
      if (error.code === "ACTION_REJECTED") {
          alert("Transaction rejected by user.");
      } else if (
          error.message &&
          error.message.includes("insufficient funds for gas")
      ) {
          alert("Insufficient funds to cover gas. Please add more ETH to your wallet.");
      } else if (
          error.data &&
          error.data.message &&
          error.data.message.includes("insufficient funds for gas")
      ) {
          alert("Insufficient funds to cover gas. Please add more ETH to your wallet.");
      } else {
          console.error("Error:", error);
      }
    }
  }

  const { contract } = useContract(
    "0xA05803e679f517cFD9f20031816aB57A7b2Fc2d3",
    MyAbi
  );

  const { data: contractMetadata } = useContractMetadata(
    contract,
    "totalSupply"
  );
  const { data: contractRead } = useContractRead(contract, "totalSupply");
  const { data: useMeta } = useMetadata(contract, "totalSupply");

  const customTheme = darkTheme({
    fontFamily: "'Teko'",
    colors: {
      modalBg: "#000",
      primaryText: "#333333",
      walletSelectorButtonHoverBg: "#EEEEEE",
      separatorLine: "#000000",
      borderColor: "#000000",
      accentButtonText: "#141452",
    },
  });

  return (
    <>
      <main className="mx-auto flex min-h-screen max-w-6xl flex-col p-6 md:p-12">
        <div className={styles.container}>
          <main className="grid gap-6 rounded-md bg-black/20 p-6 md:grid-cols-2 md:p-12">
            <div className="flex flex-col items-center justify-center space-y-6">
              <h1 className="text-4xl font-bold text-secondary text-black">
                {contractMetadata?.name} |{useMeta?.symbol}
              </h1>
              <p className="text-center leading-relaxed">
                Dive into the extraordinary world of Hood Rats, where artistry
                collides with eternity. Our collection, inspired by iconic
                animes and crafted with Hashlips' Art Generator 2.0, boasts
                10,000 unique NFTs immortalized on the Arweave blockchain
                through ArDrive. Join us in preserving creativity for
                generations – mint your Hood Rat NFT today and own a piece of
                everlasting digital artistry.
              </p>
              <p className="text-3xl text-center font-bold text-secondary text-black">
                {contractRead?.toNumber()}/10,000 Minted
              </p>

              {address ? (
                <button
                  onClick={mintNFT}
                  className="bg-blue-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full"
                >
                  Mint NFT
                </button>
              ) : (
                <p className="text-lg font-bold text-secondary text-black glow-text">
                  Please connect your wallet to mint.
                </p>
              )}

              <ConnectWallet
                btnTitle="Connect"
                className="connectButton"
                theme={customTheme}
                modalTitle="HOODRATS WEB3 MODAL"
                modalTitleIconUrl="/01.png"
                switchToActiveChain={true}
                welcomeScreen={() => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "100px",
                      }}
                    >
                      <h2>Mint @ 0.025ETH</h2>
                      <iframe
                        title="HR TBA iframe"
                        className="flex w-full max-w-lg flex-col space-y-4"
                        src="https://iframe-tokenbound.vercel.app/0xa05803e679f517cfd9f20031816ab57a7b2fc2d3/251/1"
                        style={{
                          height: "350px",
                          width: "350px",
                          border: "4px solid #000",
                        }}
                      />
                      <p>Connect To Mint</p>
                      <h3>
                        {" "}
                        <span
                          style={{
                            fontSize: "2rem",
                            fontColor: "red",
                          }}
                        >
                          {contractRead?.toNumber()}
                        </span>
                        /10,000 Minted
                      </h3>
                    </div>
                  );
                }}
              />
              <a
                href="https://4ursvwoia6ikq3e3epc6uy3hm3ebjsht3sq3hqdbowpdmog3v2iq.arweave.net/5SMq2cgHkKhsmyPF6mNnZsgUyPPcobPAYXWeNjjbrpE/index.html"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "green",
                  transition: "transform 0.3s ease-in-out",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.transform = "scale(1.1)")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
              >
                Alternative Minting Dapp
              </a>
            </div>

            <div className="flex flex-col items-center">
              <div className="flex w-full max-w-sm flex-col space-y-4">
                <div className="aspect-square w-full overflow-hidden rounded-md">
                  <iframe
                    title="HR TBA iframe"
                    className="flex w-full max-w-lg flex-col space-y-4"
                    src="https://iframe-tokenbound.vercel.app/0xa05803e679f517cfd9f20031816ab57a7b2fc2d3/275/1"
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "4px solid #000",
                    }}
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      </main>
    </>
  );
};

export default Mint;
