import React from 'react';
import './About.css';
import collectionImage from '../images/collection.png';

export default function About() {
  return (
    <section className="about">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-6 about-image">
            <img src={collectionImage} className="img-fluid" alt="Collection" />
          </div>
          <div className="col-md-6 panel-content">
            <h3 className="about-title">HOODRATS<br />
              <span>COLLECTABLES</span>
            </h3>
            <p className="about-content">Step into a realm where imagination intertwines with history.</p>
            <p className="about-content">Hood Rats is a captivating NFT collection that draws inspiration from my lifelong passion for anime and iconic fictional universes like Star Wars. Throughout our collection, you’ll discover elements reminiscent of classic animes such as Dragon Ball and Jojo’s Bizarre Adventure, among others.</p>
            <div className="row justify-content-around">
              <Card title="Tribes" value="45" />
              <Card title="Tribe Categories" value="6" />
              <Card title="Collection Size" value="10K" />
            </div>
          </div>

          <div className="row justify-content-center boxs">
            <div className="col-md-3">
              <div className="box">
                <i className="bi bi-back"></i>
                <h2>50+</h2>
                <p>Backgrounds</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="box">
                <i className="bi bi-person-lines-fill"></i>
                <h2>45+</h2>
                <p>Clothing Variations</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="box">
                <i className="bi bi-person-bounding-box"></i>
                <h2>24</h2>
                <p>Hair Styles</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="box">
                <i className="bi bi-magic"></i>
                <h2>10</h2>
                <p>Rare Weapons</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const Card = ({ title, value }) => {
  return (
    <div className="col-md-3 about-card">
      <h2>{value}</h2>
      <p>{title}</p>
    </div>
  );
};


