import React from "react";
import './Why.css'

export default function Why() {
  return(
    <section className="why">
      <div className="container-fluid">
        <div className="content">
          <h2>WAGMI <span>COMMUNITY</span></h2>
          <div className="row">
            <div className="col-md-4">
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <div className="card-icon">
                    <img className="circumfusion-img" src={require('../images/circumfusion.jpg')} alt="circumfusion" style={{ width: '95px', height: 'auto' }} />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title"><a href="https://www.amazon.com/Circumfusion-Sketchy-Labs-Community/dp/B0C523YBHN?ref=d6k_applink_bb_dls&dplnkId=b0975dab-f1c1-466d-82bb-8351120d98ff&_encoding=UTF8&tag=080969-20&linkCode=ur2&linkId=c5e0bbfb53cd2e592a3474dd36840c4f&camp=1789&creative=9325">CIRCUMFUSION</a></h5>
                      <p className="card-text">The first novella written by a Web3 community and features hoodrats.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <div className="card-icon">
                    <img className="labarberia-img" src={require('../images/LBTv2.jpg')} alt="circumfusion" style={{ width: '95px', height: 'auto' }} />

                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title"><a href="https://linktr.ee/labarberiaweb3">La Barbería</a></h5>
                      <p className="card-text">X Spaces is a bilingual community that supports Web3 education.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <div className="card-icon">
                    <img className="ardrive-img" src={require('../images/arLogo.png')} alt="ardrivelogo" style={{ width: '130px', height: 'auto' }} />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title"><a href="https://ardrive.io/hood-rats/">ArDrive Article</a></h5>
                      <p className="card-text">ARDrive's article, spotlighting our NFTs' secure and permanent storage on Arweave's decentralized platform.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row justify-content-center">
                <div className="col-md-12">
                <img className="img-fluid center-img" src={require('../images/hrred.png')} alt="center-img"/>          
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <div className="card-icon">
                    <img className="nftmup-img" src={require('../images/nftmup.jpg')} alt="nftmuppic" style={{ width: '130px', height: 'auto' }} />

                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title"><a href="https://www.spatial.io/s/NFT-UP-GAME-642d877c22862ea8f9afc638?share=8181100989405210782">NFtmUP</a></h5>
                      <p className="card-text">An ecosystem of Web3 communities whose driving force is mutual support for each other.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <div className="card-icon">
                    <img className="sketchylabs-img" src={require('../images/slLogo.png')} alt="sketchylabslogo" style={{ width: '130px', height: 'auto' }} />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title"><a href="https://sketchylabs.hashlips.io/">SKETCHY LABS</a></h5>
                      <p className="card-text">A place for artists, writers & creators to learn & collaborate.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <div className="card-icon">
                    <i className="bi bi-boxes"></i>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title"><a href="https://gallery_hoodrats.arweave.dev/">GALLERY</a></h5>
                      <p className="card-text">Explore community events through La Barbería Web3's Community Events Gallery.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}