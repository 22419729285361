import React, { useRef } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Team.css";

const teamMembers = [
  {
    name: "JHarmony eltio.eth",
    jobTitle: "Founder/Developer",
    image: require("../images/04.jpg"),
    socialLinks: {
      twitter: "https://twitter.com/TRue_JDHarmony",
      instagram: "https://instagram.com/eltio.eth",
      discord: "https://discord.gg/8NprDwRf2n",
    },
  },
  {
    name: "Hadeas Fakher",
    jobTitle: "Lead Artist",
    image: require("../images/08.jpg"),
    socialLinks: {
      twitter: "https://twitter.com/Hadies15",
      instagram: "https://folio.procreate.com/hadis",
      discord: "https://discord.gg/8NprDwRf2n",
    },
  },
];

export default function Team() {
  const carouselRef = useRef();

  console.log(teamMembers);
  return (
    <section className="team">
      <div className="container-fluid">
        <h1>
          OUR <span>TEAM</span>
        </h1>
        <div className="row">
          <div className="team-descreption col-2">
            <p>Meet Our Tribe's Skilled Crew!</p>
          </div>
          <div className="carousel-container col-10">
            <Carousel
              ref={carouselRef}
              showThumbs={false}
              showStatus={false}
              showIndicators={true}
              infiniteLoop={true}
              swipeable={true}
              emulateTouch={true}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <button
                    type="button"
                    onClick={onClickHandler}
                    className={`carousel-button carousel-button-prev ${
                      carouselRef.current?.state?.selectedItem === 0 ? "disabled" : ""
                    }`}
                    disabled={carouselRef.current?.state?.selectedItem === 0}
                  >
                    &lsaquo;
                  </button>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <button
                    type="button"
                    onClick={onClickHandler}
                    className={`carousel-button carousel-button-next ${
                      carouselRef.current?.state?.selectedItem === teamMembers.length - 1
                        ? "disabled"
                        : ""
                    }`}
                    disabled={carouselRef.current?.state?.selectedItem === teamMembers.length - 1}
                  >
                    &rsaquo;
                  </button>
                )
              }
            >
              {teamMembers.map((member, index) => (
                <div className="carousel-slide" key={index}>
                  <div className="team-member" key={index}>
                    <div className="team-member-image">
                      <img src={member.image} alt="Team Member" />
                    </div>
                    <div className="team-member-details">
                      <p>{member.jobTitle}</p>
                      <h3>
                        {member.name.split(" ").map((namePart, i) => (
                          <React.Fragment key={i}>
                            {namePart}
                            <br />
                          </React.Fragment>
                        ))}
                      </h3>
                      
                      <div className="social-links">
                        <a href={member.socialLinks.twitter} target="_blank" rel="noopener noreferrer">
                          <i className="bi bi-twitter"></i>
                        </a>
                        <a href={member.socialLinks.instagram} target="_blank" rel="noopener noreferrer">
                          <i className="bi bi-instagram"></i>
                        </a>
                        <a href={member.socialLinks.discord} target="_blank" rel="noopener noreferrer">
                          <i className="bi bi-discord"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
}
