import React, { useState } from 'react';
import ArDriveLogo from '../images/ardrive.png';
import AELogo from '../images/ae.png';
import EFLOGO from '../images/eflogo.png';
import FPLogo from '../images/fpmark.svg';
import HLLogo from '../images/hashlips.png';
import './Roadmap.css';

export default function Roadmap() {
  const [activeItem, setActiveItem] = useState('GENERATIVE ART');

  const handleClick = (item) => {
    setActiveItem(item);
  };

  return (
    <section className="roadmap">
      <div className="container-fluid">
        <h1 className="roadmap-title">HOOD RATS <span>ECOSYSTEM</span></h1>
        <div className="row">
          <div className="col-md-4">
            <div className="text-column">
              <ul>
                <li
                  className={activeItem === 'GENERATIVE ART' ? 'active' : ''}
                  onClick={() => handleClick('GENERATIVE ART')}
                >
                  <span>01</span> GENERATIVE ART
                </li>
                <li
                  className={activeItem === 'PERMANENCE' ? 'active' : ''}
                  onClick={() => handleClick('PERMANENCE')}
                >
                  <span>02</span> PERMANENCE
                </li>
                <li
                  className={activeItem === 'TOKENBOUND' ? 'active' : ''}
                  onClick={() => handleClick('TOKENBOUND')}
                >
                  <span>03</span> TOKENBOUND
                </li>
                <li
                  className={activeItem === 'LA BARBERíA' ? 'active' : ''}
                  onClick={() => handleClick('LA BARBERíA')}
                >
                  <span>04</span> LA BARBERíA
                </li>
                <li
                  className={activeItem === 'NFtmUP' ? 'active' : ''}
                  onClick={() => handleClick('NFtmUP')}
                >
                  <span>05</span> NFtmUP
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                {activeItem === 'GENERATIVE ART' && (
                  <>
                    <p className="card-phase">ART</p>
                    <h3 className="card-title">Hashlips AE 2.0</h3>
                    <p className="card-text">The foundation of the Art Engine 2.0 was inspired by the OG HashLips Art Engine, an earlier version that gained immense popularity during the rise of NFTs.</p>
                    <hr />
                    <ul>
                      <li><i className="bi bi-globe"></i> <a href='https://lab.hashlips.io/docs/art-engine'>Art Engine 2.0 Docs</a></li>
                      <li><i className="bi bi-globe"></i><a href='https://lab.hashlips.io/docs/art-engine/core-plugins/exporters/images'> Images Exporter Docs</a></li>
                      <li><i className="bi bi-youtube"></i><a href='https://www.youtube.com/c/hashlipsnft'> Educational YT Channel</a></li>
                    </ul>
                  </>
                )}
                {activeItem === 'PERMANENCE' && (
                  <>
                    <p className="card-phase">ARWEAVE</p>
                    <h3 className="card-title">DATA STORAGE</h3>
                    <p className="card-text">The Arweave network is like Bitcoin, but for data: A permanent and decentralized web inside an open ledger.</p>
                    <hr />
                    <ul>
                      <li><i className="bi bi-globe"></i> <a href='https://www.arweave.org/'>Arweave.org</a></li>
                      <li><i className="bi bi-globe"></i><a href='https://arwiki.wiki/'> Arweave Wiki</a></li>
                      <li><i className="bi bi-twitter"></i><a href='https://twitter.com/onlyarweave'> Official X</a></li>
                    </ul>
                  </>
                )}
                {activeItem === 'TOKENBOUND' && (
                  <>
                    <p className="card-phase">ERC-6551</p>
                    <h3 className="card-title">NEW STANDARD</h3>
                    <p className="card-text">An interface and registry for smart contract accounts owned by non-fungible tokens.</p>
                    <hr />
                    <ul>
                      <li><i className="bi bi-globe"></i> <a href='https://eips.ethereum.org/EIPS/eip-6551'>6551 Official EIP</a></li>
                      <li><i className="bi bi-medium"></i><a href='https://medium.com/future-primitive/tldr-nfts-have-their-own-wallets-try-it-here-http-tokenbound-org-6fac135a1f9d'> Medium Article</a></li>
                      <li><i className="bi bi-globe"></i><a href='https://ethereum-magicians.org/t/erc-6551-non-fungible-token-bound-accounts/13030/203'> Ethereum EIP-6551 Forum </a></li> 
                    </ul>
                  </>
                )}
                {activeItem === 'LA BARBERíA' && (
                  <>                    
                    <p className="card-phase">LA BARBERíA WEB3</p>
                    <h3 className="card-title">X SPACES</h3>
                    <p className="card-text">Each week, "eltio.eth" and friends invite a diverse group of guests to join them on their weekly X Space called, "La Silla."</p>
                    <hr />
                    <ul>
                      <li><i className="bi bi-twitter"></i> <a href='https://twitter.com/LaBarberiaWeb3'>Official X</a></li>
                      <li><i className="bi bi-discord"></i><a href='https://discord.gg/EwknDfNR9r'> Community Server</a></li>
                      <li><i className="bi bi-shop"></i><a href='https://linktr.ee/labarberiaweb3'> LA SILLA Booking </a></li> 
                      <li><i className="bi bi-globe"></i><a href='https://bit.ly/HOODGOTHAM'> Metaverse </a></li>
                    </ul>                  
                  </>
                )}
                {activeItem === 'NFtmUP' && (
                  <>
                    <p className="card-phase">COMMUNITY</p>
                    <h3 className="card-title">ECOSYSTEM</h3>
                    {/* <p className="card-text">Quality comes first. We took our time to plan out everything and build our production pipeline for high-quality artworks.</p> */}
                    <img className="nftmup-img" src={require('../images/nftmup.jpg')} alt="nftmupimage" style={{ width: '300px', height: 'auto' }} />

                    <hr />
                    <ul>
                      <li><i className="bi bi-twitter"></i> <a href='https://twitter.com/TRue_JDHarmony'>Jharmony</a></li>
                      <li><i className="bi bi-twitter"></i><a href='https://twitter.com/therambler_eth'> TheRambler</a></li>
                      <li><i className="bi bi-twitter"></i><a href='https://twitter.com/handsomebstrd'> HandsomeBastard </a></li> 
                      <li><i className="bi bi-globe"></i> <a href='https://x.com/TRue_JDHarmony/status/1647416072679612418?s=20'>More</a></li> 
                    </ul>                  
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                {activeItem === 'GENERATIVE ART' && (
                  <>
                    <p className="card-phase">METADATA</p>
                    <h3 className="card-title">Metadata Exporter</h3>
                    <p className="card-text">The ERC721 Metadata Exporter plugin enables the transformation and exportation of rendered attributes into ERC721 NFT standard metadata JSON format. </p>
                    <hr />
                    <ul>
                      <li><i className="bi bi-globe"></i> <a href='https://lab.hashlips.io/docs/art-engine'>ERC721 Metadata Exporter Docs</a></li>
                      <li><i className="bi bi-globe"></i><a href='https://lab.hashlips.io/docs/art-engine/core-plugins/exporters/sol-metadata'> Sol Metadata Exporter Docs</a></li>
                      <li><i className="bi bi-discord"></i><a href='https://discord.gg/hashlips-889036571385409556'> Community Server</a></li>
                    </ul>
                  </>
                )}
                {activeItem === 'PERMANENCE' && (
                  <>
                    <p className="card-phase">ARDRIVE</p>
                    <h3 className="card-title">STORE FOREVER</h3>
                    <p className="card-text">Streamline your digital world with ArDrive, the pay-once, store-forever app that simplifies file management and fuels inspiration.</p>
                    <hr />
                    <ul>
                      <li><i className="bi bi-globe"></i> <a href='https://ardrive.io/'>ArDrive</a></li>
                      <li><i className="bi bi-twitter"></i><a href='https://twitter.com/ardriveapp'> Official X</a></li>
                      <li><i className="bi bi-globe"></i><a href='https://ardrive.io/hood-rats/'> Hood Rats' Article on ArDrive</a></li>
                    </ul>
                  </>
                )}
                {activeItem === 'TOKENBOUND' && (
                  <>
                    <p className="card-phase">FUTURE PRIMITIVE</p>
                    <h3 className="card-title">TBAs</h3>
                    <p className="card-text">Tokenbound suite allow developers to easily integrate ERC-6551 accounts into their applications.</p>
                    <hr />
                    <ul>
                      <li><i className="bi bi-globe"></i> <a href='https://tokenbound.org/'>Tokenbound</a></li>
                      <li><i className="bi bi-twitch"></i><a href='https://m.twitch.tv/futureprimitivexyz/home'> Official Twitch</a></li>
                      <li><i className="bi bi-globe"></i><a href='https://docs.tokenbound.org/'> TBA Docs</a></li>
                    </ul>
                  </>
                )}
                {activeItem === 'LA BARBERíA' && (
                  <>                    
                    <p className="card-phase">COLLECTIONS & MORE</p>
                    <h3 className="card-title">COLLECTABLES</h3>
                    <p className="card-text">La Barbería Web3 community serves as a hub for creatives, collaborations, and creativity in the ever-evolving digital landscape.</p>
                    <hr />
                    <ul>
                      <li><i className="bi bi-globe"></i> <a href='https://opensea.io/collection/hood-rats'>Hood Rats</a></li>
                      <li><i className="bi bi-globe"></i><a href='https://opensea.io/collection/nuestrahistoria'> Nuestra Historia </a></li>
                      <li><i className="bi bi-globe"></i><a href='https://opensea.io/collection/sourpups'> Sour Pups </a></li>
                      <li><i className="bi bi-globe"></i><a href='https://gallery_hoodrats.arweave.dev/'> Event Gallery </a></li> 
                    </ul>                  
                  </>
                )}
                {activeItem === 'NFtmUP' && (
                  <>
                    <p className="card-phase">CIRCUMFUSION</p>
                    <h3 className="card-title">SKETCHY LABS</h3>
                    {/* <p className="card-text">Starting the production on the procedurally generated planets and the smart contract for minting.</p> */}
                    <img className="circumfusion-img" src={require('../images/circumfusion.jpg')} alt="circumfusion" style={{ width: '112px', height: 'auto' }} />
                    <img className="circumfusion-bimg" src={require('../images/circumfusion_back.jpg')} alt="circumfusionback" style={{ width: '112px', height: 'auto' }} />
                    <hr />
                    <ul>
                      
                      <li><i className="bi bi-globe"></i><a href='https://www.amazon.com/Circumfusion-Sketchy-Labs-Community/dp/B0C523YBHN?ref=d6k_applink_bb_dls&amp;dplnkId=b0975dab-f1c1-466d-82bb-8351120d98ff&_encoding=UTF8&tag=080969-20&linkCode=ur2&linkId=c5e0bbfb53cd2e592a3474dd36840c4f&camp=1789&creative=9325'> Amazon Link </a></li>
                      <li><i className="bi bi-globe"></i> <a href='https://twitter.com/sarahndipitous'>Sarah Hodsdon</a></li> 
                      <li><i className="bi bi-globe"></i><a href='https://twitter.com/ComfortCreature'> Comfort Creatures </a></li>
                      <li><i className="bi bi-globe"></i><a href='https://discord.gg/sketchy-labs-905013848271114251'> Sketchy Labs Community</a></li>
                    </ul>                  
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="row sponsors-row">
          <div className="col-12">
            <div className="sponsors">
              <img src={ArDriveLogo} alt="ArDrive Logo" className="sponsor-logo" />
              <img src={EFLOGO} alt="Ethereum Foundation Logo" className="sponsor-logo" />
              <img src={AELogo} alt="ArtEngine Logo" className="sponsor-logo" />  
              <img src={FPLogo} alt="Future Primitive Logo" className="sponsor-logo" />
              <img src={HLLogo} alt="Hashlips Logo" className="sponsor-logo" />
            </div>
          </div>
          </div>
        </div>
      </div>
    </section>
  );
}
