import React from "react";
import "./Collection.css";

export default function Collection() {
  const collections = [
    {
      name: "Bororats",
      username: "COMMON TRIBE",
      profileImg: require("../images/boro275.png"),
      image1: require("../images/boro4.png"),
      image2: require("../images/boro100.png"),
      image3: require("../images/boro189.png"),
    },
    {
      name: "Cuzcomunks",
      username: "SHORT-TAILED TRIBE",
      profileImg: require("../images/cuzco2574.png"),
      image1: require("../images/cuzco2590.png"),
      image2: require("../images/cuzco3584.png"),
      image3: require("../images/cuzco4454.png"),
    },
    {
      name: "Timucuas",
      username: "FEMALE TRIBE",
      profileImg: require("../images/timu6532.png"),
      image1: require("../images/timu8504.png"),
      image2: require("../images/timu8680.png"),
      image3: require("../images/timu9251.png"),
    },
    {
      name: "Guaranrats",
      username: "ENLARGED-EAR TRIBE",
      profileImg: require("../images/guaran4504.png"),
      image1: require("../images/guaran6060.png"),
      image2: require("../images/guaran5598.png"),
      image3: require("../images/guaran5584.png"),
    },
    {
      name: "Mayaquitos",
      username: "COMMON TRIBE",
      profileImg: require("../images/maya794.png"),
      image1: require("../images/maya1116.png"),
      image2: require("../images/maya5.png"),
      image3: require("../images/maya126.png"),
    },
    {
      name: "Guarijiorats",
      username: "COMMON TRIBE",
      profileImg: require("../images/guari1136.png"),
      image1: require("../images/guari1580.png"),
      image2: require("../images/guari26.png"),
      image3: require("../images/guari2499.png"),
    },
    //new tribes
    {
      name: "Tapirruts",
      username: "COMMON TRIBE",
      profileImg: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/20beb5b53f39d42849dc41d2964c7d327f8262b2.png",
      image1: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/6c49918fc44637e13acec6462a12525326bc9ce1.png",
      image2: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/0b592c503e15181042774cbda090b79c62f05e8c.png",
      image3: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/a607b882e897e894ae4dfc82961c2156f4a502e9.png",
    },
    {
      name: "Chimboratica",
      username: "ENLARGED-EAR TRIBE",
      profileImg: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/a713702fb81572f99eb8c1d77c051ebf38a95018.png",
      image1: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/ad193ff84c9577000802800fd05e584a58a8af9f.png",
      image2: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/954056f363a602214837497cd89c5acd68bd20ef.png",
      image3: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/51530b3882843370f46fc2ffbf1b514a5ff3a35c.png",
   },
    {
      name: "Chimúmunks",
      username: "FEMALE TRIBE",
      profileImg: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/e434ce0f630921f2b7d2dbbb7cebe979266c6039.png",
      image1: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/6c7d71783be19ed57dc31f19effd88554ae5ead6.png",
      image2: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/4b05f2f8d72c68dfaebf77f0d319fbd495fb2d5a.png",
      image3: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/e64ff83a8d09d9719fa375edbf330e95aa3f7199.png",
   },
    {
      name: "Quechuamunks",
      username: "FEMALE TRIBE",
      profileImg: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/3f2ed7187afe7d1025ee0c0ca7c5580bcdc3a1fe.png",
      image1: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/fe71f2abcfebfe2284c0d46f61b3ce5512dcb344.png",
      image2: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/f0423bc658a46b9cb3725fd797ae08f1b245d55f.png",
      image3: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/87562f20cd066d455fb4e29edff6004a9512c883.png",
   },
    {
      name: "Warangas",
      username: "FEMALE TRIBE",
      profileImg: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/38373ee9876f810bd6f47b1e42b5a7d7b64066f2.png",
      image1: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/8517e10b910d2aa8fbc55ebc47cd2312f90f365e.png",
      image2: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/d5870ac650012723f0c00f6544c9b8fea1d5fac2.png",
      image3: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/a24597a3dfd846361209d40dd38bb6d0817f98bc.png",
   },
    {
      name: "Coatlans",
      username: "MUTATED TRIBE",
      profileImg: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/800cf111362996eb17e551131d0ca97dba6d4b2e.png",
      image1: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/46dbb019aa59ba299df5ac8be94ae08b9089cf46.png",
      image2: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/9de6253cab38847ba3f7858e09399b7c5b1f0c0d.png",
      image3: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/9c1dfa80b88b8daff4c65c858c4f27aca21bd317.png",
   },
   {
     name: "Spinytail Rats",
     username: "EVOLVED TRIBE",
     profileImg: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/05e95094e9afd44eeb8d9c877c6ca838299ec4b6.png",
     image1: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/19ae32dc169558a657996a1859e88152e6d9c01b.png",
     image2: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/eaa3e1a102ef345c849316c8065662d4f274284d.png",
     image3: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/58b5148b1d698d2ef29847c8692eed2ca7368ea5.png",
  },
   {
     name: "Nightwhiskers",
     username: "EVOLVED TRIBE",
     profileImg: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/3c19a070f738c48503778774a020c36f8d9678c0.png",
     image1: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/ff719126503d4a369d2b9c6611466bf8bfdd3bf4.png",
     image2: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/d2e9c74c9cdf9a72bf40943bf88d8c46c2400345.png",
     image3: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/3535cf06a151964d7c340a1e0f8d41a7b6cc015d.png",
  },
   {
     name: "Firebelly Rats",
     username: "EVOLED TRIBE",
     profileImg: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/affaeaf645614867722beb671ecb8ac073d1c68c.png",
     image1: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/d4459858d6aafae7069a83e17a23b26b2f685739.png",
     image2: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/5b7d99fdda74c6230bdeeaf7482d6f00355a2197.png",
     image3: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/198d9b6e1a44910183db5bdd17c887c1dcdbb287.png",
  },
  {
    name: "Tainorats",
    username: "FEMALE TRIBE",
    profileImg: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/e5a3bc2dcaf5939fac1adb0a09bc4f197ab57820.png",
    image1: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/2c7fe8bb9f77996d0e338af91a3b12c3552ee899.png",
    image2: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/efc1fdd2356a2bd9cd64b4f33a8178ed6ce754c8.png",
    image3: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/66fb8f52847705ecd7850524190931f41a1a611f.png",
 },
  {
    name: "Huitzillans",
    username: "FEMALE TRIBE",
    profileImg: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/ff9913bbb245162033e4ed0932fea907633195a0.png",
    image1: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/97dc0d3c0dc0302ae302c0dbb81a3d125ebcc92a.png",
    image2: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/cd938dfa850a0e2db80876bde6bb1caea3e9ea87.png",
    image3: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/3177293f36de8c9c786fad4157a5131439c14705.png",
 },
  {
    name: "Tzotzilrats",
    username: "SHORT-TAILED TRIBE",
    profileImg: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/b999710de1c192b831a4095cc1d9467f4ffe2930.png",
    image1: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/adbfc3e1093ff8084c058f347e3a827812acb06a.png",
    image2: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/644f48cb0db517e86daad53b1c81f22bb69240ca.png",
    image3: "https://arweave.net/KZ8ni5LqSnu-i5fT6WUnje4u-Va0J2UdYw2amb7OBOM/b197c8a84c9211d76d87ad9344a88af769928682.png",
 }
  ];

  return (
    <section className="collection">
      <div className="container-fluid">
        <div className="row">
          <h1>
            TRIBAL<br></br>
            <span>TRAITS</span>
          </h1>
        </div>
        <div className="row">
          {collections.map((collection, index) => (
            <div className="col-md-4" key={index}>
              <div className="collection-card">
                <div className="collection-card-header">
                  <img src={collection.profileImg} alt="Profile" />
                  <div className="collection-card-header-info">
                    <h3>{collection.name}</h3>
                    <p>{collection.username}</p>
                  </div>
                </div>
                <div className="collection-card-images">
                  <div className="collection-card-left-image">
                   <img src={collection.image1} alt="Image1" />
                  </div>
                  <div className="collection-card-right-images">
                    <img src={collection.image2} alt="Image2" />
                    <img src={collection.image3} alt="Image3" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
